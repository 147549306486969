.cyan-teal {
  --separater: #03a9f412;
  --color: #004d40;

  --background-color: #e0f7fa;
  --background-color1: #b2ebf2;
  --background-color2: #80deea;
  --background-color-bt-bg: #00bcd4;

  /* Text Colors */
  --text-color: #006064;
  --text-color1: #004d40;
  --text-color2: #00796b;
  --heading-color: #009688;
  --heading-color1: #00897b;
  --paragraph-color: #00796b;
  --paragraph-color1: #00838f;

  /* Span Colors */
  --span-color: #009688;
  --span-color1: #80cbc4;
  --span-color2: #4db6ac;

  /* Icon Colors */
  --icon-color: #00acc1;
  --icon-color1: #26c6da;
  --icon-color2: #00b8d4;

  /* Border Colors */
  --border-color: #0097a7;
  --border-color1: #00796b;
  --border-color2: #4dd0e1;
  --border-color3: #00bcd4;

  /* Hover Colors */
  --hover-color: #4dd0e1;
  --hover-color1: #26a69a;
  --hover-color2: #009688;
  --hover-color3: rgba(0, 188, 212, 0.2);
  --hover-color-btn: #00e5ff;
  --hover-color-btn1: #84ffff;
  --hover-color-btn2: #00b8d4;
  --hover-color-icon: #18ffff;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
  --shadow-color1: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
  --shadow-color2: rgba(0, 0, 0, 0.1);
  --shadow-color3: rgba(0, 0, 0, 0.1);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #004d40;
}

.cyan-teal-dark {
  --separater: #03a9f412;
  --color: #a7ffeb;

  --background-color: #004d40;
  --background-color1: #00695c;
  --background-color2: #00796b;
  --background-color-bt-bg: #009688;

  /* Text Colors */
  --text-color: #e0f7fa;
  --text-color1: #a7ffeb;
  --text-color2: #64ffda;
  --heading-color: #00bcd4;
  --heading-color1: #00acc1;
  --paragraph-color: #4db6ac;
  --paragraph-color1: #b2dfdb;

  /* Span Colors */
  --span-color: #80cbc4;
  --span-color1: #26c6da;
  --span-color2: #009688;

  /* Icon Colors */
  --icon-color: #00e5ff;
  --icon-color1: #00acc1;
  --icon-color2: #0097a7;

  /* Border Colors */
  --border-color: #00796b;
  --border-color1: #00897b;
  --border-color2: #4dd0e1;
  --border-color3: #00bcd4;

  /* Hover Colors */
  --hover-color: #009688;
  --hover-color1: #00796b;
  --hover-color2: #00acc1;
  --hover-color3: rgba(0, 188, 212, 0.2);
  --hover-color-btn: #00bfa5;
  --hover-color-btn1: #00e5ff;
  --hover-color-btn2: #009688;
  --hover-color-icon: #1de9b6;
  /* Shadow */
  --shadow-color: 0 4px 8px rgba(255, 255, 255, 0.1), 0 0 4px rgba(0, 0, 0, 0.5);
  --shadow-color1: 0 4px 8px rgba(255, 255, 255, 0.1),
    0 0 4px rgba(0, 0, 0, 0.5);
  --shadow-color2: rgba(255, 255, 255, 0.2);
  --shadow-color3: rgba(255, 255, 255, 0.1);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #b2ebf2;
}
