.table-container {
  width: 100%;
  overflow-x: auto;
}
.table-header {
  padding: 15px;
  background-color: var(--background-color);
  height: var(--height1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--margin5);
  border-radius: var(--border-radius3);
  color: var(--text-color1);
  font-family: var(--font-family);
  }
  .opr-div {
    display: flex;
    align-items: center;
  }
  .column-chooser {
    position: relative;
  }
  
  .column-chooser-modal {
    width: 230px;
    overflow-y: auto;
    max-height: 210px;
    border-radius: var(--border-radius2);
    padding: 20px;
    background: var(--background-color1);
    position: absolute;
    top: 85%;
    left: 85%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family);
}

.column-BTN{
  display: flex;
  height: var(--height9);
  width: 98%;
  border-radius: var(--border-radius2);
  background-color: var(--background-color);
  color: var(--text-color1);
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size1);
  font-weight: var(--font-weight5);
}
  
.column-BTN:hover {
  box-shadow: var(--shadow-color1) !important;
  transform: scale(0.98);
  background-color: var(--background-color2);
  color: var(--hover-color);
}
  .column-chooser-btn {
    margin: 6px;
    background-color: transparent;
    color: var(--text-color1) !important;
    border: var(--border1);
    cursor: pointer;
    display: flex;
    align-items: flex-end;
  }
  .column-chooser-btn:hover {
    color: var(--hover-color-btn) !important;
    transform: scale(0.98); 
  }
  
  .add-btn {
    display: flex;
    margin: 4px;
    background-color: var(--background-color-bt-bg);
    color: var(--text-color2);
    border: none;
    cursor: pointer;
    margin-left: 10px;
    width: var(--width6);
    height: var(--height9);
    align-items: center;
    justify-content: space-around;
    border-radius: var(--border-radius2);
    font-family: var(--font-family);
  }
  .add-btn:hover {
    background-color: var(--hover-color-btn);
  }
  
  .search-container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    color: var(--text-color1);
    pointer-events: none;
    font-size: var(--font-size2);
  }
  .search-boxx {
    margin: 5px;
    border-radius: var(--border-radius1);
    width: var(--width8);
    height: var(--height9);
    padding-left: 30px;
    background-color: var(--background-color2);
    color: var(--text-color1);
    border: 1px solid var(--border);
    border-bottom:var(--border6);
    box-shadow: none;
    outline: none;
    transition: border-color 0.3s ease;
  }
  .search-boxx:focus {
    border-bottom-color: var(--hover-color-btn1);
    border-top: var(--border2);
    border-left: var(--border2);
    border-right: var(--border2);
  }
  .highlight {
    background-color: #3cc0fd8a;
    color: inherit;
  }
  .separator {
    width: var(--width10);
    background-color: var(--scrollbar);
    margin: var(--margin6);
    display: inline-block;
    height: var(--height);
    align-self: center;
  }
  
  .export-pdf-excel {
    position: relative;
  }
  .export-pdf-excel-dropdown div {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  
  .export-pdf-excel-dropdown input {
    margin-right: 5px;
  }

  .export-pdf-excel-dropdown {
    display: none;
    position: absolute;
    font-family: var(--font-family);
    background: var(--background-color1);
    border: 1px solid var(--border2);
    padding: 10px;
    margin-top: 5px;
  }
  
  .export-pdf-excel-btn {
    display: flex;
    margin: 6px;
    background-color: transparent;
    color: var(--text-color1) !important;
    border: var(--border1);
    cursor: pointer;
    width: 50px;
    align-items: center;
    justify-content: space-between;
  }
  .export-pdf-excel-btn:hover {
    color: var(--hover-color-btn) !important;
    transform: scale(0.98); 
  }
  
  .label-div {
    font-family: var(--font-family);
    font-size: var(--font-size6);
    font-weight: var(--font-weight4);
    margin: 15px;
  }

  .label-div1{
    font-family: var(--font-family);
    font-size: var(--font-size6);
    font-weight: var(--font-weight4);
    margin: 15px;
    display: none;
  }
  
.table-wrapper{
  border: var(--border3);
  border-radius: var(--border-radius3);
  max-height: 63vh;
  overflow-y: auto;
}
.table {
  color: var(--text-color1);
  font-family: var(--font-family);
  font-size: var(--font-size8);
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color1);
  border-radius: var(--border-radius3);
}
thead{
  border-top-left-radius: var(--border-radius3);
  border-top-right-radius: var(--border-radius3);
}
.table th {
  position: relative;
  height: var(--height8);
  width: 10%;
  padding: 6px;
  text-align: left;
  border-bottom: var(--border10);
}
.table thead th {
  position: sticky;
  top: 0;
  background-color: var(--background-color1);
  z-index: 10;
}
.text-select{
  width: 5% !important;
}
.centered-cell {
  text-align: center !important;  
  color: #0f6cbd !important;   
  font-size: 20px !important;    
}
tbody{
  border-bottom-left-radius: var(--border-radius3);
  border-bottom-right-radius: var(--border-radius3);
}
/* tbody tr:hover{
 background-color: #7bbbf34e;
} */
.table td {
  position: relative;
  padding: var(--padding);
  padding-left: 5px;
  text-align: left;
  border-bottom: var(--border5);
}
.no-data{
  font-size: 20px;
  color: var(--hover-color);
}
.text-right{
  text-align: right !important;
}

.text-right2{
  /* text-align: right !important; */
  position: relative;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 100%; /* Positions below the button */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  width: 200px;
}
td button{
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.table-footer {
  margin-top: 10px;
}
select{
  background-color:var(--background-color);
  color: var(--text-color1);
  margin: 5px;
  border-radius: var(--border-radius2);
  width: var(--width2);
  height: var(--height9);
  padding-left: 6px;
  border: var(--border2);
  border-bottom: var(--border8);
  box-shadow: none;
  outline: none;
  transition: border-color 0.3s ease;
}

.pagination{
  display: flex;
  justify-content: space-between;
}
.pg-2-container{
  display: flex;
  align-items: center;
}
.pgn-btn{
  border: none;
    background-color: transparent;
    margin: 2px 15px;
    color: var(--text-color1) !important;
}
.pgn-btn:hover {
  background-color: transparent;
  color:var(--hover-color-btn1) !important ;
}

.status {
  font-family: var(--font-family);
  margin-left: 10px;
  color:var(--color) ;
  font-size: var(--font-size1);
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox1 {
  appearance: none;
  width: var(--width17);
  height: var(--height16);
  border: 2px solid gray;
  border-radius: 2px;
  cursor: default;
  position: relative;
  transition: all 0.3s ease;
}

/* Custom checkbox when checked */
.custom-checkbox1:checked {
  background-color: var(--background-color-bt-bg);
  border-color: var(--border-color1); 
}

.custom-checkbox1:checked::after {
  content: "✓";
  position: absolute;
  color: var(--text-color2);
  font-size: var(--font-size8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table {
  background-color: var(--background-color);
}

@media (max-width: 1000px) {

  .dropdown-menu {
    display: flex;
  }
  
  .column-chooser-modal {
    width: 230px;
    overflow-y: auto;
    max-height: 210px;
    border-radius: var(--border-radius2);
    padding: 20px;
    background: var(--background-color1);
    position: absolute;
    top: 85%;
    left: 85%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family);
}

.column-BTN{
  display: flex;
  height: var(--height9);
  width: 98%;
  border-radius: var(--border-radius2);
  background-color: var(--background-color);
  color: var(--text-color1);
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size1);
  font-weight: var(--font-weight5);
}
}



@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 4px;
  }

  .dropdown-menu {
    display: flex;
    align-items: flex-start;
  }

  .expanded-row {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .expanded-cell {
    width: 45%; /* Display two cells per row in expanded view */
    margin: 4px 0;
  }

  .column-chooser-modal {
    width: 187px;
    overflow-y: auto;
    max-height: 210px;
    padding: 20px;
    position: absolute;
    top: 84%;
    left: 82%;
    transform: translate(-50%, -50%);
}

.column-BTN{
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: flex-start;
}
.label-div {
  font-family: var(--font-family);
  font-size: var(--font-size6);
  font-weight: var(--font-weight4);
  margin: 15px;
  display: none;
}

.label-div1{
  font-family: var(--font-family);
  font-size: var(--font-size6);
  font-weight: var(--font-weight4);
  margin: 15px;
display: block;
}
}

@media (max-width: 380px) {

  .column-chooser-modal {
    display: flex;
    width: 165px;
    overflow-y: auto;
    max-height: 210px;
    padding: 15px;
    position: absolute;
    top: 86%;
    left: 65%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
}

.dropdown-menu {
  display: flex;
}


.column-BTN{
  display: flex;
  height: 35px;
  width: 80%;
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size8);
}
}

.three-dots-container {
  display: none;
}


@media (max-width: 503px) {
.opr-div {
  display: none;
  align-items: center;
}

.three-dots-container {
  display: block;
}

}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;  /* Adjust based on your table height */


.dropdown-menu {
  display: flex;
  align-items: start;
}


}

@media (max-width: 350px) {
  .search-boxx{
    width: 100px;
  }
  .label-div {
    font-family: var(--font-family);
    font-size: var(--font-size6);
    font-weight: var(--font-weight4);
    margin: 15px;
    display: none;
  }

  .label-div1{
    font-family: var(--font-family);
    font-size: var(--font-size6);
    font-weight: var(--font-weight4);
    margin: 15px;
display: block;
  }
  
  }


/* Newly Added By Sami */
.dropdown-item {
  display: flex;
  padding: 5px; 
  gap: 10px; /* Space between icon and text */
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.dropdown-icon {
  font-size: 20px; /* Adjust icon size */
  color: #0f6cbd; /* Icon color */
}

.dropdown-text {
  flex: 1; /* Push text to occupy remaining space */
  font-size: 14px;
  color: #333;
}

/* Optional: Add right-aligned arrow for Export */
.dropdown-arrow {
  margin-left: auto; /* Push arrow to the far right */
  font-size: 16px;
  color: #999;
}

/* Main Export Item */
.export-dropdown {
  position: relative; /* For dropdown positioning */
}

.export-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  cursor: pointer;
}

/* Export Dropdown */
.export-options {
  position: absolute;
  top: 100%; /* Below the Export button */
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  width: 250px; /* Adjust as needed */
}

.export-option {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 10px; /* Space between icon and text */
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.export-option:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.export-option-icon {
  font-size: 20px;
  color: #0f6cbd;
}

/* Optional: Add media query for smaller screens */
@media (max-width: 768px) {
  .export-options {
    width: 100%; /* Full width dropdown for smaller screens */
  }
}

/* Divider between dropdown items */
.dropdown-divider {
  border: none;
  border-top: 1px solid #ddd; /* Light gray line */
  margin: 8px 0; /* Adjust spacing above and below */
  width: 100%; /* Full width */
}
