body {
  background-color: var(--background-color2);
  margin: 0;

}
.root{
  background-color: var(--background-color2);
}

::selection {
  background: transparent;
  color: inherit;
}

::-moz-selection {
  background: transparent;
  color: inherit;
}
