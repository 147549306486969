.lookup-container {
  position: relative;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 220px;
  margin-right: 15px;
}

.lookup-input {
  display: flex;
  height: 30px !important;
  padding: 5px !important;
  border: none;
  border-bottom: var(--border6) !important;
  background-color: var(--background-color2) !important;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
}

.lookup-input:hover {
  background-color: var(--hover-color1) !important;
  border-bottom-color: var(--hover-color-btn1) !important;
}

.lookup-input:focus-within {
  background-color: var(--hover-color1) !important;
  border-bottom-color: var(--hover-color-btn1) !important;
  outline: none;
}

.lookup-label {
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
  color: var(--text-color1);
}

.lookup-input.filled .lookup-label {
  font-size: 14px;
  top: -10px;
  color: var(--hover-color);
}
.Dp-container {
  padding: 16px;
  width: 190px;
  background-color: var(--background-color1);
}

.icon-input {
  display: flex;
  align-items: center;
}

.LP-search-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.LP-search-icon {
  position: absolute;
  left: 10px;
  color: var(--text-color1);
  pointer-events: none;
  font-size: var(--font-size2);
}

.LP-search-input {
  margin: 5px;
  border-radius: var(--border-radius1);
  width: 100%;
  height: var(--height9);
  padding-left: 30px;
  background-color: var(--background-color2);
  color: var(--text-color1);
  border: 1px solid var(--border);
  border-bottom: var(--border6);
  box-shadow: none;
  outline: none;
  transition: border-color 0.3s ease;
}
.LP-search-input:focus {
  border-bottom-color: var(--hover-color-btn1);
  border-top: var(--border2);
  border-left: var(--border2);
  border-right: var(--border2);
}

.icon-dropdown {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon-option {
  display: flex;
  height: var(--height9);
  width: 92%;
  border-radius: var(--border-radius2);
  background-color: var(--background-color);
  color: var(--text-color1);
  padding: 5px;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size1);
  font-weight: var(--font-weight5);
  cursor: pointer;
}

.icon-option:hover {
  box-shadow: var(--shadow-color1) !important;
  transform: scale(0.98);
  background-color: var(--background-color2);
  color: var(--hover-color);
}

.icon-option.disabled {
  color: gray;
  cursor: not-allowed;
}

.cancel-Sel-btn {
  display: flex !important;
  background-color: var(--background-color1) !important;
  color: var(--text-color2) !important;
  border: var(--hover-color-btn1) !important;
  cursor: pointer !important;
  width: 100% !important;
  height: var(--height9) !important;
  align-items: center !important;
  justify-content: space-around !important;
  border-radius: var(--border-radius2) !important;
  font-family: var(--font-family) !important;
  color: var(--text-color1) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}
.cancel-Sel-btn:hover {
  background-color: var(--hover-color1) !important;
  transform: scale(0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.24);
}

@media (max-width: 600px) {
  .lookup-container {
    position: relative;
    margin-bottom: 2px;
    width: 92%;
  }
  .Dp-container {
    padding: 13px;
    width: 94vw;
    background-color: var(--background-color1);
    position: fixed;
    height: 100vh;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
  }
  .LP-search-container {
    width: 100%;
  }
  .icon-dropdown {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .icon-option {
    width: 98%;
  }
}
