/* main*/
.main-container{
    display: flex;
    height: 99vh;
    flex-direction: column;
}
.content-container{
    display: flex;
    height: calc(99vh - 65px);
}
/* main-end*/

/* head */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--heading-color);
    height: 48px;
    position: static;
    top: 0;
    left: 0;
    width: auto;
    z-index: 1;
    box-shadow: var(--shadow-color);
    padding: 10px 10px;
    background-color: var(--background-color);
    font-family:var(--font-family);
}
.head-icon {
    color: var(--icon-color) !important;
    font-size: var(--font-size5)!important;
    font-weight: var(--font-weight3);
    cursor: pointer;
}
.head-icon:hover {
    color: var(--hover-color) !important;
}
.header-title {
    font-size: var(--font-size4);
    font-weight: var(--font-weight3);
    flex-grow: 1;
    text-align: left;
    margin-left: 16px;
}
.theme-modal {
    width: 150px;
    overflow-y: auto;
    height: 100vh;
    padding: 20px;
    background:  var(--background-color1);
    position: absolute;
    top: 0%;
    right: 0%;
}
.theme-grid {
    display: grid;
    grid-template-columns: repeat(3, 50px); /* 3 themes per row */
    gap: 10px;
  }
  
  .theme-circle {
    width: 30px;
    height: 30px;
    border-radius: var(--border-radius5);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: transform 0.2s;
    background-color: var(--border-color);
  }
  
  .theme-circle:hover {
    transform: scale(1.1);
  }

.header-right{
    display: flex;
    align-items: center;
}
.header-icon {
    color: var(--icon-color) !important;
    font-size: var(--font-size5)!important;
    font-weight: var(--font-weight3);
    cursor: pointer;
}
.header-icon:hover {
    color: var(--hover-color-icon) !important;
}
/* head-end */

/* sidebar */
.sidebar {
    background-color:  var(--background-color1);
    color: var(--text-color);
    font-weight: var(--font-weight5);
    font-size: var(--font-size1);
    font-family:var(--font-family);
    width: 250px;
    height: calc(99vh - 65px);
    position: sticky;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width 0.4s ease-in-out;
    position: relative;
    flex: 0 0 auto;
}
.sidebar.collapsed {
    width: 60px;
}
.sidebar .tree-item {
    cursor: pointer;
}
.t-item {
    color: var(--text-color1);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: var(--height);
    padding-top: 10px;
    padding-bottom: 10px;
    margin: var(--margin1);
    font-size: var(--font-size1);
    font-weight: var(--font-weight5);
    font-family:var(--font-family);
}
.t-item:hover {
    background-color: var(--hover-color1);
    color: var(--hover-color);
}
.sidebar .tree-item .icon {
    position: absolute;
    display: flex;
    width: 60px;
    height: 22px;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size3);
}
.sidebar .tree-item .text {
    margin-left: 60px;
    width: auto;
    height: auto;
}
.sidebar .arrow {
    margin-left: auto;
    margin-right: 15px;
    height: 10px;
    width: 10px;
    font-size: var(--font-size);
    color: var(--text-color1);
}
.sub-item {
    display: flex;
    height: var(--height);
    padding-top: 10px;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    transition: background 0.3s;
    align-items: center;
    color: var(--text-color1);
    font-weight: var(--font-weight3);
    font-size: var(--font-size1);
    font-family:var(--font-family);
}
.sub-item:hover {
    background-color: var(--hover-color1);
    color: var(--hover-color);
  }
.separater {
    width: 100%;
    background-color: var(--separater);
    height: var(--height5);
    align-self: center;
}
/* sidebar-end */

/* contant */
.content {
    flex-grow: 1;
    padding: 18px;
    transition: margin-left 0.6s ease;
    overflow-x: auto;
}
/* contant-ends */


@media (max-width: 768px) {
    .content {
        flex-grow: 1;
        padding: 20px;
        transition: margin-left 0.6s ease;
        position: absolute;
    }
    .sidebar.collapsed {
        width: 0px;
    }
    .sidebar {
        z-index: 1501;
    }
  }




  