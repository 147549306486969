  .profile-container {
    border-radius: 8px;
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .profile-image-wrapper {
    flex: 0 0 80px;
    margin-right: 20px;
  }
  .profile-icon {
    font-size: 80px; /* Matches the image size */
    color: #ccc; /* Light gray color */
    border: 2px solid #ddd;
    border-radius: 50%;
    padding: 5px;
    background: #f9f9f9;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
  }
  
  .profile-info {
    flex: 1;
  }
  
  .profile-name {
    font-size: 20px;
    margin: 0 0 5px;
  }
  
  .profile-id {
    font-size: 14px;
    color: #777;
    margin: 0;
  }
  
  .change-password-btn {
    margin-top: 10px;
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .change-password-btn:hover {
    background: #0056b3;
  }
  
  .details-section {
    margin-bottom: 30px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-field {
    flex: 1;
  }
  
  .profile-input,
  select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contacts-address-section {
    display: flex;
    gap: 20px;
  }
  
  .contacts-section,
  .address-section {
    flex: 1;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #ddd;
  }
  
  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .status-dot.salaried {
    background: green;
  }
  