/* App.css or your global CSS file */
/* working in translation */
.rtl {
  direction: rtl;
  text-align: right; /* Align text to the right */
}

.ltr {
  direction: ltr;
  text-align: left; /* Align text to the left */
}

/* Custom scrollbar for WebKit browsers (Chrome, Safari, Edge) */

/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 4px; /* For vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--background-color1);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar); /* Scrollbar thumb color */
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 6px; /* For horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--background-color1);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar); /* Scrollbar thumb color */
}

