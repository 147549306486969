.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: white;
}
.signup-container-wrap {
    background-color: transparent;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    height: auto;
    border-bottom: 1px solid #cac6c6a3;
    border-left: 1px solid #cac6c6a3;
    border-right: 1px solid #cac6c6a3;
}
.signup-heading {
    text-align: center;
    margin-bottom: 20px;
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    color: #242424;
    line-height: 24px;
    font-weight: 500;
    font-size: 24px;
  }
.signup-input-container {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    position: relative;
    flex-direction: column;
}

.signup-input {
    padding: 10px;
    border: none;
    border-bottom: 2px solid gray;
    background-color: #e9ecef !important;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
    margin-bottom: 28px;
}

.create-password-toggle {
    position: absolute;
    right: 18px;
    top: 68%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #03a9f4;
}
.create-password-toggle.active {
color: #0f6cbd;
  }

  .Confirmed-Password-toggle {
    position: absolute;
    right: 18px;
    top: 88%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #03a9f4;
}
.Confirmed-Password-toggle.active {
color: #0f6cbd;
  }

.signup-input:hover {
    background-color: #ced4da;
    border-bottom: 2px solid black;
  }
  
  .signup-input:focus {
    background-color: #ced4da;
    border-bottom: 2px solid #03a9f4;
    outline: none; 
  }
  .signup-button {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #0f6cbd;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
  background-color: #115fa3;
  color: white;
}
.signup-button:active {
  background-color: #026592;
  color: #e0e0e0; 
  transform: scale(0.98); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, .24);
}
.signup-createAccountButton,
.signup-oauthButton {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    background-color: #ffffff;
    color: #242424;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-createAccountButton:hover,
.signup-oauthButton:hover {
transition: background-color 0.3s;
  background-color: #ebebeb
}
.signup-createAccountButton:active,
.signup-oauthButton:active {
  background-color: #b3b3b3;
  color: #212224;
  transform: scale(0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, .24);
}
.signup-link {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  width: 125px;
  margin-left: 3px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 35px;
  color: #0f6cbd;
  text-decoration: none;
}

.signup-link:hover {
    color: #026592;
}

.privacy-link{
    width: 125px;
    text-align: center;
    margin-top: 10px;
    color: #242424b3;
    text-decoration: underline;
  }

  .privacy-link:hover {
    color: #026592;
}

.separatar {
    width: 100%;
    background-color: #ccc;
    height: 1px;
    align-self: center;
  }

  @media (max-width: 576px) {
    .signup-container-wrap {
        border: none;
        box-shadow:none;
        height: 60vh;
    }
}
