.form-header h2 {
    margin: 0;
}
.buttons-edit{
    display: flex;
}
.edit-btn {
    display: flex;
    margin: 4px;
    background-color: var(--background-color-bt-bg);
    color: var(--text-color2);
    border: none;
    cursor: pointer;
    margin-left: 10px;
    width: var(--width2);
    height: var(--height9);
    align-items: center;
    justify-content: space-around;
    border-radius: var(--border-radius2);
    font-family: var(--font-family);
  }
  .edit-btn:hover {
    background-color: var(--hover-color-btn);
  }


  .close-btn {
    display: flex;
    margin: 4px;
    background-color: var(--background-color-bt-bg);
    color: var(--text-color2);
    border: none;
    cursor: pointer;
    margin-left: 10px;
    width: var(--width6);
    height: var(--height9);
    align-items: center;
    justify-content: space-around;
    border-radius: var(--border-radius2);
    font-family: var(--font-family);
  }
  .close-btn:hover {
    background-color: var(--hover-color-btn);
  }
/* Grid layout with stacked labels and inputs below */
.grid-container {
    display: grid;
    gap: 20px;
}

/* Responsive grid based on screen width */
@media (max-width: 460px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 461px) and (max-width: 992px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 993px) and (max-width: 1199px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1200px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
}
