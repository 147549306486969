/* Dark Greenish Theme */
.dark-greenish {
  --separater: #03a9f412;
  --color: #1b3a1b;

  --background-color: #d9f9d9;
  --background-color1: #b2e1b2;
  --background-color2: #99d699;
  --background-color-bt-bg: #4caf50;

  /* Text */
  --text-color: #2a572a;
  --text-color1: #1b3a1b;
  --text-color2: #ffffff;
  --heading-color: #388e3c;
  --heading-color1: #4caf50;
  --paragraph-color: #4caf50;
  --paragraph-color1: #b2e1b2;

  /* Span */
  --span-color: #2a572a;
  --span-color1: #4caf50;
  --span-color2: #b2e1b2;

  /* Icon */
  --icon-color: #2a572a;
  --icon-color1: #4caf50;
  --icon-color2: #d9f9d9;

  /* Border */
  --border-color: #388e3c;
  --border-color1: #4caf50;
  --border-color2: #99d699;
  --border-color3: #b2e1b2;

  /* Hover */
  --hover-color: #4caf50;
  --hover-color1: #d9f9d9;
  --hover-color2: #388e3c;
  --hover-color3: rgba(42, 85, 42, 0.1); /* Light Green */
  --hover-color-btn: #4caf50;
  --hover-color-btn1: #388e3c;
  --hover-color-btn2: #99d699;
  --hover-color-icon: #b2e1b2;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(42, 85, 42, 0.3), 0 0 4px rgba(42, 85, 42, 0.4);
  --shadow-color1: 0 4px 8px rgba(42, 85, 42, 0.3),
    0 0 4px rgba(42, 85, 42, 0.4);
  --shadow-color2: rgba(42, 85, 42, 0.4);
  --shadow-color3: rgba(42, 85, 42, 0.2);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #2a572a;
}

.dark-greenish-dark {
  --separater: #03a9f412;
  --color: #b5d8b7;

  --background-color: #163216;
  --background-color1: #2a572a;
  --background-color2: #317a31;
  --background-color-bt-bg: #4caf50;

  /* Text */
  --text-color: #d1ebe4;
  --text-color1: #b5d8b7;
  --text-color2: #ffffff;
  --heading-color: #a5d6a7;
  --heading-color1: #81c784;
  --paragraph-color: #b5d8b7;
  --paragraph-color1: #9bc29b;

  /* Span */
  --span-color: #d1ebe4;
  --span-color1: #a5d6a7;
  --span-color2: #4caf50;
  /* Icon */
  --icon-color: #d1ebe4;
  --icon-color1: #b5d8b7;
  --icon-color2: #2a572a;

  /* Border */
  --border-color: #4caf50;
  --border-color1: #66bb6a;
  --border-color2: #317a31;
  --border-color3: #a5d6a7;

  /* Hover */
  --hover-color: #66bb6a;
  --hover-color1: #4caf50;
  --hover-color2: #99cc99;
  --hover-color3: rgba(76, 175, 80, 0.2);
  --hover-color-btn: #81c784;
  --hover-color-btn1: #4caf50;
  --hover-color-btn2: #66bb6a;
  --hover-color-icon: #b2e1b2;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(0, 51, 0, 0.3), 0 0 4px rgba(0, 51, 0, 0.4);
  --shadow-color1: 0 4px 8px rgba(0, 51, 0, 0.25), 0 0 4px rgba(0, 51, 0, 0.35);
  --shadow-color2: rgba(0, 51, 0, 0.4);
  --shadow-color3: rgba(0, 51, 0, 0.25);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #b2e1b2;
}
