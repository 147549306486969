.Reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.Reset-container-wrap {
    background-color: transparent;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: auto;
    border-bottom: 1px solid #c8c5c57d;
    border-left: 1px solid #c8c5c57d;
    border-right: 1px solid #c8c5c57d;
}

.Reset-heading {
    text-align: center;
    margin-bottom: 20px;
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    color: #242424;
    line-height: 24px;
    font-weight: 500;
    font-size: 24px;
}
.Reset-input-container {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    position: relative;
    flex-direction: column;
}

.Reset-input {
    padding: 10px;
    border: none;
    border-bottom: 2px solid gray;
    background-color: #e9ecef !important;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
    margin-bottom: 28px;
}
.Reset-input:hover {
    background-color: #ced4da;
    border-bottom: 2px solid black;
  }
  
  .Reset-input:focus {
    background-color: #ced4da;
    border-bottom: 2px solid #03a9f4;
    outline: none; 
    
  }
  .Reset-button {
    font-weight: 500;
    font-size: 14px;
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    background-color: #0f6cbd;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: default;
    transition: background-color 0.3s;
  }
  
  .Reset-button:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    background-color: #115fa3;
    color: white;
  }
  .Reset-button:active {
    background-color: #026592;
    color: #fff; 
    transform: scale(0.98); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, .24);
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    
  }
.Reset-link {
    font-weight: 500;
    font-size: 14px;
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    width: 125px;
    text-align: center;
    margin-top: 10px;
    color: #0f6cbd;
    margin-left: 5px;
    text-decoration: underline;
    cursor: default;
}
.Reset-link:hover {
    color: #115fa3;
}
.Reset-link:active {
    background-color: transparent;
    color: #026592; 
    transform: scale(0.98); 
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    
  }

@media (max-width: 576px) {
    .Reset-container-wrap {
        border: none;
        box-shadow:none;
        height: 60vh;
    }
}