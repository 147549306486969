/* Aqua Green Theme */
.Pinkish {
  --separater: #dadbdd;
  --color: #ad1457;
  /* Light Mode Colors */
  --background-color: #f8bbd0;
  --background-color1: #f6aac3ed;
  --background-color2: #ff80ab;
  --background-color-bt-bg: #d2176b;

  /* Text */
  --text-color: #880e4f;
  --text-color1: #ad1457;
  --text-color2: #ffffff;
  --heading-color: #c2185b;
  --heading-color1: #e91e63;
  --paragraph-color: #ec407a;
  --paragraph-color1: #b71c1c;

  /* Span */
  --span-color: #d81b60;
  --span-color1: #e57373;
  --span-color2: #f06292;

  /* Icon */
  --icon-color: #880e4f;
  --icon-color1: #b71c1c;
  --icon-color2: #fce4ec;

  /* Border */
  --border-color: #c2185b;
  --border-color1: #ad1457;
  --border-color2: #e57373;
  --border-color3: #f8bbd0;

  /* Hover */
  --hover-color: #f50057;
  --hover-color1: #8b888818;
  --hover-color2: #e91e63;
  --hover-color3: rgba(244, 143, 177, 0.1);
  --hover-color-btn: #c51189;
  --hover-color-btn1: #d81b60;
  --hover-color-btn2: #ff4081;
  --hover-color-icon: #f50057;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(255, 87, 136, 0.2),
    0 0 4px rgba(244, 67, 54, 0.15);
  --shadow-color1: 0 4px 8px rgba(233, 30, 99, 0.2),
    0 0 4px rgba(240, 98, 146, 0.15);
  --shadow-color2: rgba(236, 64, 122, 0.4);
  --shadow-color3: rgba(255, 145, 164, 0.3);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;
  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;
  /* scrollbar */
  --scrollbar: #d2176b;
}

.Pinkish-dark {
  --separater: #dadbdd;
  --color: #f9a5c2;

  /* Dark Mode Colors */
  --background-color: #3b233b;
  --background-color1: #552b55;
  --background-color2: #6a1b4b;
  --background-color-bt-bg: #ad1457;

  /* Text */
  --text-color: #f7c0d3;
  --text-color1: #f9a5c2;
  --text-color2: #fcd1e5;
  --heading-color: #ff7292;
  --heading-color1: #f8b4cc;
  --paragraph-color: #ff5484;
  --paragraph-color1: #ff89a9;

  /* Span */
  --span-color: #f48fb1;
  --span-color1: #ff80ab;
  --span-color2: #ffb6c1;

  /* Icon */
  --icon-color: #ff80ab;
  --icon-color1: #f06292;
  --icon-color2: #ffc1e3;

  /* Border */
  --border-color: #9c2951;
  --border-color1: #b43563;
  --border-color2: #f99db8;
  --border-color3: #f48fb1;

  /* Hover */
  --hover-color: #e91e63;
  --hover-color1: #f865985c;
  --hover-color2: #ff5484;
  --hover-color3: rgba(255, 120, 145, 0.2);
  --hover-color-btn: #d81b60;
  --hover-color-btn1: #e91e63;
  --hover-color-btn2: #ff4081;
  --hover-color-icon: #ff80ab;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(220, 120, 180, 0.2),
    0 0 4px rgba(150, 50, 100, 0.3);
  --shadow-color1: 0 4px 8px rgba(220, 90, 140, 0.2),
    0 0 4px rgba(170, 60, 90, 0.25);
  --shadow-color2: rgba(245, 150, 180, 0.4); /* Brighter pinkish shadow */
  --shadow-color3: rgba(255, 175, 195, 0.3);

  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";

  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;
  /* scrollbar */
  --scrollbar: #d2176b;
}
