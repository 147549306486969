/* purple Theme */
.purple {
  --separater: #dadbdd;
  --color: #6a1b9a;

  /* Background Colors */
  --background-color: #f3e5f5;
  --background-color1: #e1bee7;
  --background-color2: #ce93d8;
  --background-color-bt-bg: #ba68c8;

  /* Text Colors */
  --text-color: #4a148c;
  --text-color1: #6a1b9a;
  --text-color2: #7b1fa2;
  --heading-color: #9c27b0;
  --heading-color1: #ab47bc;
  --paragraph-color: #8e24aa;
  --paragraph-color1: #673ab7;

  /* Span Colors */
  --span-color: #ab47bc;
  --span-color1: #ba68c8;
  --span-color2: #ce93d8;

  /* Icon Colors */
  --icon-color: #9c27b0;
  --icon-color1: #7b1fa2;
  --icon-color2: #e1bee7;

  /* Border Colors */
  --border-color: #6a1b9a;
  --border-color1: #8e24aa;
  --border-color2: #ba68c8;
  --border-color3: #e1bee7;

  /* Hover Colors */
  --hover-color: #a63aba;
  --hover-color1: rgba(186, 104, 200, 0.2);
  --hover-color2: #9c27b0;
  --hover-color3: rgba(186, 104, 200, 0.2);
  --hover-color-btn: #9a4ba8;
  --hover-color-btn1: #ea80fc;
  --hover-color-btn2: #e040fb;
  --hover-color-icon: #aa00ff;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
  --shadow-color1: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
  --shadow-color2: rgba(0, 0, 0, 0.1);
  --shadow-color3: rgba(0, 0, 0, 0.1);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: Helvetica;
  --font-family1: "Segoe UI";
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #ab47bc;
}

.purple-dark {
  --separater: #dadbdd;
  --color: #cdb4e9;

  /* Background Colors */
  --background-color: #3e2445;
  --background-color1: #49294f;
  --background-color2: #5a3060;
  --background-color-bt-bg: #7b1fa2;

  /* Text Colors */
  --text-color: #e5d1f5;
  --text-color1: #cdb4e9;
  --text-color2: #b39ddb;
  --heading-color: #ce80ff;
  --heading-color1: #d1a0ff;
  --paragraph-color: #d593ff;
  --paragraph-color1: #e1b9ff;

  /* Span Colors */
  --span-color: #ba68c8;
  --span-color1: #d793ff;
  --span-color2: #ea80fc;

  /* Icon Colors */
  --icon-color: #d593ff;
  --icon-color1: #d1a0ff;
  --icon-color2: #a770ff;

  /* Border Colors */
  --border-color: #b02fd7;
  --border-color1: #b06cd8;
  --border-color2: #d693ff;
  --border-color3: #e1b9ff;

  /* Hover Colors */
  --hover-color: #c500f9;
  --hover-color1: #a249d57c;
  --hover-color2: #e180ff;
  --hover-color3: rgba(208, 120, 255, 0.2);
  --hover-color-btn: #c500f9;
  --hover-color-btn1: #a050d0;
  --hover-color-btn2: #b000ff;
  --hover-color-icon: #7c4dff;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(200, 150, 255, 0.2),
    0 0 4px rgba(180, 100, 255, 0.4);
  --shadow-color1: 0 4px 8px rgba(200, 150, 255, 0.2),
    0 0 4px rgba(180, 100, 255, 0.4);
  --shadow-color2: rgba(224, 150, 255, 0.6);
  --shadow-color3: rgba(224, 180, 255, 0.4);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: Helvetica;
  --font-family1: "Segoe UI";
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #e1bee7;
}
