.dashboard {
    font-family: 'Arial', sans-serif;
    background-color: #f4f5f7;
    max-height: 85vh;
    overflow-y: auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filters button {
    margin-right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px 15px;
    cursor: pointer;
  }
  
  .filters .active {
    background-color: #007bff;
    color: #fff;
  }
  
  .kpi-section {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .kpi-card {
    flex: 1;
    margin: 0 10px;
    background-color: #fff;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .kpi-card span.positive {
    color: green;
  }
  
  .kpi-card span.negative {
    color: red;
  }
  
  .charts {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .chart {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .table-section {
    margin-top: 20px;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table .tab-th, table .tab-td{
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  