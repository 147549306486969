/* Metallic 2 Theme */
.metallic2 {
  --separater: #dadbdd;
  --color: #f1f1f1;
  --color: rgb(71, 158, 245);
  /* backgrounds */
  --background-color: rgb(255, 255, 255);
  --background-color1: rgb(255, 255, 255);
  --background-color2: rgb(250, 250, 250);
  --background-color-bt-bg: rgb(15, 108, 189);
  /* text */
  --text-color: #757575;
  --text-color1: #424141;
  --text-color2: #ffffff;
  --heading-color: #424141;
  --heading-color1: #575555;
  --paragraph-color: #bdbdbd;
  --paragraph-color1: #3a3b3c;
  /*span  */
  --span-color: #757575;
  --span-color1: #757575;
  --span-color2: #757575;
  /* icon */
  --icon-color: #757575;
  --icon-color1: #242424;
  --icon-color2: #fafafa;
  /* border */
  --border-color: #9e9e9e;
  --border-color1: rgb(15, 108, 189);
  --border-color2: #9e9e9e;
  --border-color3: #9e9e9e;
  /* hover */
  --hover-color: #479ef5;
  --hover-color1: #eaeee9;
  --hover-color2: #479ef5;
  --hover-color3: #03a9f412;
  --hover-color-btn: #115fa3;
  --hover-color-btn1: #0f6cbd;
  --hover-color-btn2: #479ef5;
  --hover-color-icon: #479ef5;
  /* shadow */
  --shadow-color: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  --shadow-color1: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  --shadow-color2: rgba(97, 97, 97, 0.4);
  --shadow-color3: rgba(97, 97, 97, 0.4);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;
  /* scrollbar */
  --scrollbar: #479ef5;
}

.metallic2-dark {
  --separater: #03a9f412;
  --color: #141414;
  --color: #f1f1f1;

  /* backgrounds */
  --background-color: rgb(41, 41, 41);
  --background-color1: rgb(38 38 38);
  --background-color2: #1f1f1f;
  --background-color-bt-bg: rgb(71, 158, 245);
  /* text */
  --text-color: #fafafa;
  --text-color1: #ffffff;
  --text-color2: #424141;
  --heading-color: #ffffff;
  --heading-color1: #f6f4f4;
  --paragraph-color: #e0e0e0;
  --paragraph-color1: #f9f6ee;
  /* span */
  --span-color: #9e9e9e;
  --span-color1: #9e9e9e;
  --span-color2: #9e9e9e;
  /* icon */
  --icon-color: #fafafa;
  --icon-color1: #fafafa;
  --icon-color2: #fafafa;
  /* border */
  --border-color: #515159;
  --border-color1: rgb(71, 158, 245);
  --border-color2: #515159;
  --border-color3: #515159;
  /* hover */
  --hover-color: #62abf5;
  --hover-color1: #03a9f412;
  --hover-color2: #0c3b5e;
  --hover-color3: #3d3d3d;
  --hover-color-btn: #62abf5;
  --hover-color-btn1: #479ef5;
  --hover-color-btn2: #479ef5;
  --hover-color-icon: #62abf5;
  /* shadow */
  --shadow-color: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  --shadow-color1: 0 1px 3px rgba(0, 0, 0, 0.24);
  --shadow-color2: rgba(97, 97, 97, 0.4);
  --shadow-color3: rgba(97, 97, 97, 0.4);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";

  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #cccccc40;
  --border3: 1px solid rgb(88, 85, 85);
  --border4: 1px solid #515159;

  --border5: 2px solid #b4b3b3a3;
  --border6: 2px solid #797979;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;
  /* scrollbar */
  --scrollbar: #0c3b5e;
}
