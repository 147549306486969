/* LemonChiffon */
.LemonChiffon {
  --separater: #03a9f412;
  --color: #d99a6c;

  /* Background Colors */
  --background-color: #ffc196;
  --background-color1: #ffcba4;
  --background-color2: #fdd5b1;
  --background-color-bt-bg: #e6be8a;

  /* Text Colors */
  --text-color: #da8a67;
  --text-color1: #d99a6c;
  --text-color2: #8b5a2b;
  --heading-color: #c88a65;
  --heading-color1: #9e7b5a;
  --paragraph-color: #6e4f3a;
  --paragraph-color1: #4b3c2d;

  /* Span Colors */
  --span-color: #da8a67;
  --span-color1: #ffcba4;
  --span-color2: #edc9af;

  /* Icon Colors */
  --icon-color: #c88a65;
  --icon-color1: #d99a6c;
  --icon-color2: #fcd667;

  /* Border Colors */
  --border-color: #d99a6c;
  --border-color1: #e6be8a;
  --border-color2: #da8a67;
  --border-color3: #ffcba4;

  /* Hover Colors */
  --hover-color: #fcd667;
  --hover-color1: #e6be8a;
  --hover-color2: #fdd5b1;
  --hover-color3: rgba(255, 203, 164, 0.2);
  --hover-color-btn: #fdd5b1;
  --hover-color-btn1: #ffcba4;
  --hover-color-btn2: #da8a67;
  --hover-color-icon: #e6be8a;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.05);
  --shadow-color1: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.1);
  --shadow-color2: rgba(0, 0, 0, 0.05);
  --shadow-color3: rgba(0, 0, 0, 0.1);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #b05c52;
}

.LemonChiffon-dark {
  --separater: #03a9f412;
  --color: #ffe4b5;

  /* Background Colors */
  --background-color: #c95a49c6;
  --background-color1: #b05c52;
  --background-color2: #f1bb8c;
  --background-color-bt-bg: #d99a6c;

  /* Text Colors */
  --text-color: #ffefd5;
  --text-color1: #ffe4b5;
  --text-color2: #ffdab9;
  --heading-color: #fcd667;
  --heading-color1: #ffcba4;
  --paragraph-color: #edc9af;
  --paragraph-color1: #da8a67;

  /* Span Colors */
  --span-color: #fcd667;
  --span-color1: #ffcba4;
  --span-color2: #da8a67;

  /* Icon Colors */
  --icon-color: #fdd5b1;
  --icon-color1: #edc9af;
  --icon-color2: #ffcba4;

  /* Border Colors */
  --border-color: #da8a67;
  --border-color1: #ffcba4;
  --border-color2: #e6be8a;
  --border-color3: #d99a6c;
  /* Hover Colors */
  --hover-color: #ffcba4;
  --hover-color1: #fdd5b1;
  --hover-color2: #fcd667;
  --hover-color3: rgba(255, 218, 185, 0.2);
  --hover-color-btn: #ffdab9;
  --hover-color-btn1: #da8a67;
  --hover-color-btn2: #e6be8a;
  --hover-color-icon: #fcd667;

  /* Shadow */
  --shadow-color: 0 4px 12px rgba(0, 0, 0, 0.5),
    0 0 8px rgba(255, 255, 255, 0.1);
  --shadow-color1: 0 4px 10px rgba(0, 0, 0, 0.5),
    0 0 6px rgba(255, 255, 255, 0.1);
  --shadow-color2: rgba(255, 255, 255, 0.2);
  --shadow-color3: rgba(255, 255, 255, 0.1);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #ffcba4;
}
