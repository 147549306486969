.add-form-container {
  background-color: var(--background-color1) !important;
  padding: 20px;
  max-width: 600px;
  margin: auto;
}
.add-form {
  display: flex;
  flex-direction: column;
}
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.save-btn {
  display: flex !important;
  margin-right: 28px !important;
  background-color: var(--background-color-bt-bg) !important;
  color: var(--text-color2) !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: 10px !important;
  width: var(--width3) !important;
  height: var(--height9) !important;
  align-items: center !important;
  justify-content: space-around !important;
  border-radius: var(--border-radius2) !important;
  font-family: var(--font-family) !important;
}
.save-btn:hover {
  background-color: var(--hover-color-btn) !important;
  transform: scale(0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.24);
}
.cancel-btn {
  display: flex !important;
  margin-right: 8px !important;
  background-color: var(--background-color1) !important;
  color: var(--text-color2) !important;
  border: var(--hover-color-btn1) !important;
  cursor: pointer !important;
  margin-left: 10px !important;
  width: var(--width3) !important;
  height: var(--height9) !important;
  align-items: center !important;
  justify-content: space-around !important;
  border-radius: var(--border-radius2) !important;
  font-family: var(--font-family) !important;
  color: var(--text-color1) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}
.cancel-btn:hover {
  background-color: var(--hover-color1) !important;
  transform: scale(0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.24);
}
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Full width on small screens */
  max-width: 500px; /* Max width for larger screens */
  box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.12); /* Box shadow */
  padding: 16px; /* Padding */
  border-radius: 8px; /* Border radius */
  background-color: var(--background-color1) !important;
}
@media (max-width: 600px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
  .modal-box {
    display: flex !important;
    position: absolute;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
    width: 100%;
    height: 100vh;
    box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.12);
    padding: 16px;
    border-radius: 8px;
    background-color: var(--background-color1) !important;
    flex-direction: column;
    align-items: stretch;
  }
}
