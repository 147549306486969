.details-container {
  height: 85vh;
  font-family: Arial, sans-serif;
}

.detail-header{
padding: 15px;
background-color: var(--background-color);
height: var(--height1);
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: var(--margin5);
border-radius: var(--border-radius3);
color: var(--text-color1);
font-family: var(--font-family);
}

.panels {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.left-panel {
  margin-top: var(--margin5);
  width: 100%;
  display: flex;
  margin-right: 10px;
  border-radius: 5px;
  max-height: 330px !important;
  overflow-y: auto;
  justify-content: center;
}

.form-container {
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.form-header {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}


.grid-container {
  display: grid;
  gap: 12px;
}

.form-group {
height: 50px;
  display: flex;
  flex-direction: column;
}

.right-panel {
  height: 410px !important;
  overflow-y: auto;
  flex: 2 1;
  margin-top: var(--margin5);
  border-radius: 5px;
}

.tab-container{
  max-width: 100%;
  height: 35px;
  border-bottom: 1px solid;
  margin-bottom: 20px;
}
.tab{
  border: none;
  height: 100%;
  width: auto;
}

.tab:hover {
  background-color: #ced4da;
  border-bottom: 2px solid black;
}

.tab:focus {
  background-color: #ced4da;
  border-bottom: 2px solid #03a9f4;
  outline: none; 
}

@media (max-width: 460px) {
  .panels {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .panels {
    grid-template-columns: 1fr;
  }

  .left-panel {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100% !important;
  }
}
