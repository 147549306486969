/* Maroonish Reddish Theme */
.maroonish-reddish {
  --separater: #03a9f412;
  --color: #c62828;

  --background-color: #f2cbd1;
  --background-color1: #e4abb4;
  --background-color2: #ebbfc5;
  --background-color-bt-bg: #d32f2f;

  /* Text */
  --text-color: #d32f2f;
  --text-color1: #c62828;
  --text-color2: #ffffff;
  --heading-color: #b71c1c;
  --heading-color1: #ef9a9a;
  --paragraph-color: #ef5350;
  --paragraph-color1: #f8bbd0;

  /* Span */
  --span-color: #d32f2f;
  --span-color1: #ef5350;
  --span-color2: #f48fb1;

  /* Icon */
  --icon-color: #d32f2f;
  --icon-color1: #e57373;
  --icon-color2: #f8bbd0;

  /* Border */
  --border-color: #c62828;
  --border-color1: #b71c1c;
  --border-color2: #e57373;
  --border-color3: #ef9a9a;

  /* Hover */
  --hover-color: #f06292;
  --hover-color1: #ffebee;
  --hover-color2: #ff7961;
  --hover-color3: rgba(211, 47, 47, 0.1);
  --hover-color-btn: #d32f2f;
  --hover-color-btn1: #c62828;
  --hover-color-btn2: #ef5350;
  --hover-color-icon: #ef9a9a;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(211, 47, 47, 0.2),
    0 0 4px rgba(211, 47, 47, 0.4);
  --shadow-color1: 0 4px 8px rgba(211, 47, 47, 0.2),
    0 0 4px rgba(211, 47, 47, 0.4);
  --shadow-color2: rgba(255, 82, 82, 0.4);
  --shadow-color3: rgba(239, 83, 80, 0.2);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #c62828;
}

.maroonish-reddish-dark {
  --separater: #03a9f412;
  --color: #ff7961;

  --background-color: #3e1f20;
  --background-color1: #4a282a;
  --background-color2: #5b3034;
  --background-color-bt-bg: #d32f2f;

  /* Text */
  --text-color: #ef9a9a;
  --text-color1: #ff7961;
  --text-color2: #ffffff;
  --heading-color: #ff5252;
  --heading-color1: #ef5350;
  --paragraph-color: #ff7961;
  --paragraph-color1: #f48fb1;

  /* Span */
  --span-color: #ef9a9a;
  --span-color1: #ff5252;
  --span-color2: #ff7961;

  /* Icon */
  --icon-color: #ef5350;
  --icon-color1: #f06292;
  --icon-color2: #ffebee;

  /* Border */
  --border-color: #ff5252;
  --border-color1: #f44336;
  --border-color2: #ff7961;
  --border-color3: #ef5350;

  /* Hover */
  --hover-color: #ff7961;
  --hover-color1: #5b3034;
  --hover-color2: #f44336;
  --hover-color3: rgba(239, 83, 80, 0.2);
  --hover-color-btn: #d32f2f;
  --hover-color-btn1: #c62828;
  --hover-color-btn2: #ef5350;
  --hover-color-icon: #ff7961;

  /* Shadow */
  --shadow-color: 0 4px 8px rgba(211, 47, 47, 0.2),
    0 0 4px rgba(239, 83, 80, 0.4);
  --shadow-color1: 0 4px 8px rgba(211, 47, 47, 0.2),
    0 0 4px rgba(239, 83, 80, 0.4);
  --shadow-color2: rgba(255, 82, 82, 0.4);
  --shadow-color3: rgba(239, 83, 80, 0.2);
  /* font-size ,font-weight ,font-family*/
  --font-size: x-small;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 20px;
  --font-size5: 21px;
  --font-size6: 24px;
  --font-size7: 28px;
  --font-size8: 12px;
  --font-weight: 100;
  --font-weight1: 200;
  --font-weight2: 300;
  --font-weight3: 400;
  --font-weight4: 500;
  --font-weight5: 600;
  --font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, avenir next,
    avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-family1: Monospace;
  --font-family2: "Segoe UI";
  --font-family3: "Segoe UI";
  --font-family4: "Segoe UI";
  --font-family5: "Segoe UI";
  --font-family6: "Segoe UI";
  /* padding ,margin ,border ,border-radius */
  --padding: 0px;
  --padding1: 2px;
  --padding2: 4px;
  --padding3: 5px;
  --padding4: 6px;
  --padding5: 7px;
  --padding6: 8px;
  --padding7: 9px;
  --padding8: 10px;

  --margin: 0px;
  --margin1: 1px 0;
  --margin2: 10px;
  --margin3: 15px;
  --margin4: 25px;
  --margin5: 30px;
  --margin6: 0 1px;

  --border: 1px solid #cccccc40;
  --border1: none;
  --border2: 1px solid #ccc;
  --border3: 1px solid #d5cfcf;
  --border4: 1px solid #515159;

  --border5: 2px solid #d5cfcf;
  --border6: 2px solid #ccc;
  --border7: 2px solid rgb(97, 97, 97);
  --border8: 2px solid #515159;

  --border9: 3px solid #cccccc40;
  --border10: 3px solid #ccc;
  --border11: 3px solid rgb(97, 97, 97);
  --border12: 3px solid #515159;

  --border-radius: 2px;
  --border-radius1: 4px;
  --border-radius2: 5px;
  --border-radius3: 8px;
  --border-radius4: 10px;
  --border-radius5: 50px;
  --border-radius6: 25px;
  /* height */
  --height: 25px;
  --height1: 22px;
  --height2: 18px;
  --height3: 16px;
  --height4: 27px;
  --height5: 1px;
  --height6: 2px;
  --height7: 3px;
  --height8: 30px;
  --height9: 32px;
  --height10: 35px;
  --height11: 40px;
  --height12: 45px;
  --height13: 50px;
  --height14: 12px;
  --height15: 13px;
  --height16: 14px;
  --height17: 15px;
  /* width */
  --width: 40px;
  --width1: 60px;
  --width2: 80px;
  --width3: 90px;
  --width4: 100px;
  --width5: 110px;
  --width6: 115px;
  --width7: 120px;
  --width8: 140px;
  --width9: 160px;
  --width10: 1px;
  --width11: 2px;
  --width12: 3px;
  --width13: 4px;
  --width14: 5px;
  --width15: 12px;
  --width16: 13px;
  --width17: 14px;
  --width18: 15px;
  --width19: 16px;

  --scrollbar: #ff7961;
}
