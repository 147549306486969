/* Input.css */

.textarea-container {
  position: relative;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 220px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.Add-Input {
  height: 30px;
  padding: 5px !important;
  border: none !important;
  border-bottom: var(--border6) !important;
  background-color: var(--background-color2) !important;
  transition:
    background-color 0.3s,
    border-color 0.3s !important;
  cursor: text !important;
  width: 98%;
  resize: none;
  outline: none;
}
.copy-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.copy-button :hover {
  background-color: var(--hover-color1) !important;
  border-bottom: 2px solid var(--hover-color-btn1) !important;
}

.Add-Input:hover {
  background-color: var(--hover-color1) !important;
  border-bottom: 2px solid black !important;
}

.Add-Input:focus {
  background-color: var(--hover-color1) !important;
  border-bottom-color: var(--hover-color-btn1) !important;
}

.Input-label {
  position: absolute;
  font-size: 14px;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
  color: var(--text-color1);
  pointer-events: none;
}

.Input-label.filled {
  top: -10px;
  left: 10px;
  font-size: 14px;
  color: var(--hover-color);
}

@media (max-width: 600px) {
  .textarea-container {
    position: relative;
    margin-bottom: 2px;
    width: 92%;
  }
}


.datetime-container {
  position: relative;
  margin: 1em 0;
}

.Add-Input {
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
}

.Input-label {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  transition: all 0.2s ease;
  color: gray;
}

.Input-label.filled {
  top: -1em;
  font-size: 0.8em;
  color: #000;
}

.Add-Input.focused + .Input-label {
  top: -1em;
  font-size: 0.8em;
  color: #000;
}
