.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}
.signin-container-wrap {
    background-color: transparent;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    height: auto;
    border-bottom: 1px solid #cac6c6a3;
    border-left: 1px solid #cac6c6a3;
    border-right: 1px solid #cac6c6a3;
}
.signin-heading {
    text-align: center;
    margin-bottom: 20px;
    font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    color: #242424;
    line-height: 24px;
    font-weight: 500;
    font-size: 24px;
  }
.input-container {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    position: relative;
    flex-direction: column;
}

.signin-input {
    padding: 10px;
    border: none;
    border-bottom: 2px solid gray;
    background-color: #e9ecef !important;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: default;
    margin-bottom: 28px;
}

.password-toggle {
    position: absolute;
    right: 18px;
    top: 70%;
    transform: translateY(-50%);
    cursor: default;
    color: #0f6cbd;
    font-size: 18px;
}
.password-toggle.active {
  color: #026592; 
  
}

.signin-input:hover {
    background-color: #ced4da;
    border-bottom: 2px solid black;
  }
  
  .signin-input:focus {
    background-color: #ced4da;
    border-bottom: 2px solid #03a9f4;
    outline: none; 
  }
  .signin-rememberMe {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: column-reverse;
    font-size: 14px;
  }
  
  .signin-rememberMe label {
    color: #242424;
    margin-bottom: 6px;
    margin-left: 5px;
  }

  .signin-button {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #0f6cbd;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: default;
  transition: background-color 0.3s;
}

.signin-button:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
  background-color: #115fa3;
  color: white;
}
.signin-button:active {
  background-color: #026592;
  color: #e0e0e0; 
  transform: scale(0.98); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, .24);
}
.signin-createAccountButton,
.signin-oauthButton {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    background-color: #ffffff;
    color: #242424;
    border: none;
    border-radius: 2px;
    cursor: default;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    display: flex;
    align-items: center;
    justify-content: center;
}

.signin-createAccountButton:hover,
.signin-oauthButton:hover {
transition: background-color 0.3s;
  background-color: #ebebeb
}
.signin-createAccountButton:active,
.signin-oauthButton:active {
  background-color: #b3b3b3;
  color: #212224;
  transform: scale(0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, .24);
}
.signin-link {
  font-weight: 500;
  font-size: 14px;
  font-family:"Segoe UI", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  width: 125px;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 35px;
  color: #0f6cbd;
  text-decoration: none;
  cursor: default;
}

.signin-link:hover {
  color: #115fa3;
}
.signin-link:active {
  background-color: transparent;
  color: #026592; 
  transform: scale(0.98); 
  transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
  
}
.custom-checkbox {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid gray;
    border-radius: 2px;
    cursor: default;
    position: relative;
    transition: all 0.3s ease;
  }
  
  /* Custom checkbox when checked */
  .custom-checkbox:checked {
    background-color: #0f6cbd;
    border-color: #0f6cbd; 
  }
  
  .custom-checkbox:checked::after {
    content: "✓";
    position: absolute;
    color: white;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 576px) {
    .signin-container-wrap {
        border: none;
        box-shadow:none;
        height: 60vh;
    }
}
