/* src/components/ThemeTransition.css */
.theme-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color:  var(--background-color); /* Set a base color for the new theme */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .roller-brush {
    width: 100px; /* Adjust based on your image size */
    animation: rotate 1s linear infinite; /* Rotating animation */
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-bar {
    margin-top: 20px; /* Spacing between the roller and loading bar */
    position: relative;
    width: 300px; /* Width of the loading bar */
    height: 4px; /* Height of the loading bar */
    background-color: #e0e0e0; /* Light background for the loading bar */
    overflow: hidden; /* Ensure anything overflowing is hidden */
  }
  
  .dotted-loader {
    position: absolute;
    width: 100%;
    height: 100%; /* Match the height of the loading bar */
    background-color: var(--background-color2); /* Color of the loading bar */
    animation: loading 1s linear infinite; /* Loading animation */
    transform: translateX(-100%); /* Start hidden to the left */
  }
  
  @keyframes loading {
    0% {
      transform: translateX(-100%); /* Start from left */
    }
    100% {
      transform: translateX(100%); /* Move to the right */
    }
  }
  